
@font-face {
  font-family: 'Polo';
  src: url('@{path}/font/polor_regular-webfont.eot'); /* IE9 Compat Modes */
  src: url('@{path}/font/polor_regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{path}/font/polor_regular-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('@{path}/font/polor_regular-webfont.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Polo';
  src: url('@{path}/font/polo_bold-webfont.eot'); /* IE9 Compat Modes */
  src: url('@{path}/font/polo_bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{path}/font/polo_bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('@{path}/font/polo_bold-webfont.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Polo';
  src: url('@{path}/font/polo_italic-webfont.eot'); /* IE9 Compat Modes */
  src: url('@{path}/font/polo_italic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{path}/font/polo_italic-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('@{path}/font/polo_italic-webfont.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Polo';
  src: url('@{path}/font/polo_bolditalic-webfont.eot'); /* IE9 Compat Modes */
  src: url('@{path}/font/polo_bolditalic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{path}/font/polo_bolditalic-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('@{path}/font/polo_bolditalic-webfont.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Polo Light';
  src: url('@{path}/font/polo22rleicht-webfont.eot'); /* IE9 Compat Modes */
  src: url('@{path}/font/polo22rleicht-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{path}/font/polo22rleicht-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('@{path}/font/polo22rleicht-webfont.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
}



