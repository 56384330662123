/* CARDS */

/*.card-deck {
	&.card-deck-1 {
		.card {
			display: flex;

			max-width: 100%;
		}
	}

	&.card-deck-2 {
		.card {
			max-width: 50%;
		}
	}

	&.card-deck-3 {
		.card {
			max-width: 33.332%;
		}
	}

	&.card-deck-4 {
		.card {
			max-width: 24.998%;
		}
	}

	&.card-deck-6 {
		.card {
			max-width: 16.665%;
		}
	}

	&.card-deck-8 {
		.card {
			max-width: 12.499%;
		}
	}
}*/


/* cards fix pre tri stlpce */

@media (min-width: 768px) {
	.three-column.card-grid-md-2 .card-tile {
		flex: 0 0 33.33%;
		max-width: 33.33%;
	}
}

@media (max-width: 767px) {

	.vertical-scroll {
		position: relative;
		padding-right: 0;
		&:after {
			content: '';
			display: block;
			position: absolute; 
			top: 0;
			right: 0;
			bottom: 0;
			width: 3rem;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
			background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
		}
		.card-grid {
			flex-wrap: nowrap;
			overflow-x: scroll; 
			margin-right: 1rem;
			.card-tile {
		     	min-width: 75%;
			}
		}
	}

}
.card.no-overflow {
	overflow: hidden;
}

.IEfix({  
	.card-img {
		height: 100%; //fix for flex in IE 11
	}
});

/* Homepage - rozcestníkový prvok 3, pod carouselom  */
.card-homepage {
	.card-header {
		padding: 0.5rem 0.0rem;
	}

	.card-inner-frame {
		border: 1px solid @gray3;
		min-height: 13.2rem;
		position: relative;

		.card-img-wrapper {
			height: 125px;
			width: 100%;
			overflow: hidden;
		}

		.card-img {
			object-fit: cover;
			height: 125px;
			.IEfix({  
				height: auto;
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			});
		}

		.card-title {
			margin-top: 0.7rem;
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: block;
		}

	}

	.card-body {
		padding-left: 0;
		padding-right: 0;
	}


	@media (max-width: 767px) {
		.card-inner-frame {
			width: 50%;
			min-height: 0;
			.card-img-wrapper {
				height: auto;
			}
			.card-title {
				position: absolute;
			    right: -100%;
			    width: 100%;
			    top: 0;
			    margin: 0;
			    padding: 0 0.7rem;
			}
		}
	}
}


/* mala vec pre skupina-zse */
.homepage-cards-container {
	margin-top: -3rem;
}

.card-homepage-skupina {
	border: 1px solid @gray3;
	transition: all 0.4s ease;

	&.red-border {
		border: 1px solid @red;
	}

	.card-title {
		margin-top: 0.7rem;
		margin-left: 0.7rem;
		margin-right: 0.7rem;
		display: block;
	}

	.card-img-wrapper {
		height: 125px;
		width: 100%;
		overflow: hidden;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.card-img {
		object-fit: cover;
		height: 125px;

		.IEfix({  
			height: auto;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		});
	}

	.card-text {
		margin-left: 0.7rem;
		margin-right: 0.7rem;
		font-size: 0.939rem;
		flex: 1 1 auto;
	}

	.card-footer {
		padding-left: 0.7rem;
		padding-right: 0.7rem;
	}
}

@media (max-width: 575px) {
	.homepage-cards-container ~ .carousel {
		display: none;
	}

	.homepage-cards-container {
		margin-top: 0;
		padding-left: 0;
		padding-right: 0;

		.card-grid.mx-1 {
			margin-left: 0 !important;
			margin-right: 0 !important;

			.card-tile {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}


}

.card-big-image-top {
	border: 1px solid @gray3;

	.card-title {
		margin-top: 0.7rem;
		margin-left: 0.7rem;
		margin-right: 0.7rem;
		margin-bottom: 0;
		display: block;
		text-align: center;
	}

	.card-img-wrapper {
		height: 220px;
		width: 100%;
		overflow: hidden;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.card-img {
		object-fit: cover;
		height: 220px;

		.IEfix({  
			height: auto;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		});
	}

	.card-text {
		margin-left: 0.7rem;
		margin-right: 0.7rem;
		margin-bottom: 0.7rem;
		font-size: 0.939rem;
		flex: 1 1 auto;
		text-align: center;
	}
}

.card-noimg {
	.card-body {
		padding-top: 0;
	}

	.card-text {
		padding-top: 0.4rem;
	}
}

.card-info-boxes {
	> .card {
		padding: 1.3rem 0.9rem;

		.card-header {
			padding-top: 0;
			padding-bottom: 0;
		}
		.card-body {
			padding-bottom: 0;
		}
	}
}

.card {
	.IEfix({  
		width:100%;
	});

	.img-cover {
		max-height: 250px;
		width: 100%;
		overflow: hidden;
		height: 250px;

		background-repeat: no-repeat;
		background-size: cover;
	}

	.card-img {
		-o-object-fit: cover;
		object-fit: cover;
	}
}

/* Homepage - magazin box */

.card-magazin {
	.card-body {
		padding: 0;

		.card-text {
			ul {
				padding-top: 1.25rem;
			}
		}
	}

	.card-footer {
		padding: 0;
	}
}

.card-bordered {
	border: 1px solid @gray3;
}

/* Rozcestník na stránke Zariadenia do domácnosti */

.card-katalog-rozc1 {
	border: 1px solid @gray3;
	color: @primary-color;
	text-decoration: none;
	background-color: @white;

	&:hover {
		text-decoration: none;
	}

	.card-header {
		padding-top: 2rem;
		padding-bottom: 0;
	}

	.card-body {
		padding-bottom: 2rem;
	}

	&.hide {
		background-color: transparent;
		border: 1px solid transparent;
	}


	@media (max-width: 767px) {
		.card-body {
			padding: 1rem 0.5rem 2rem 0.5rem;
		}
	}
	
}

.card-produkt-box {
	border: 1px solid @gray3;

	.card-img-top {
		height: 180px;
		object-fit: cover;
		border-bottom: 1px solid @gray3;
	}

	.card-title {
		color: @highlight-color;
		height: 4rem;

		&.black {
			color: @black;
		}
		&.secondary {
			color: @secondary-color;
		}
	}

	.card-body {
		ul {
			padding-left: 1em;

			li {
				margin-bottom: 0.162rem;
				font-size: 0.95rem;
			}
		}
	}

	.cena {
		/*font-weight: 600;*/
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}

	.card-text {
		margin-bottom: 1rem;
		.btn {
			display: inline-block;
			font-size: 1rem;
			margin-right: 0.25rem;
			margin-bottom: 0.25rem;
		}
	}
 
	.card-html-desc {
		margin-bottom: 1rem;
	}
 
	.bonuses { 
		margin-bottom: 1rem;
	}
	.bonus {
		color: @green;
		height: 4rem;

		i {
			padding-right: 0.7rem;
			font-size: 1.5rem;
			display: inline-block;
			vertical-align: top;
		}

		.icon-elektrina:before {
			color: @green;
		}

		span {
			line-height: 1.5rem;
			display: inline-block;
			max-width: calc(~"100% - 50px");
		}
	}
}

.card-promo-box {	
	border: 1px solid @gray3;
	background-size: cover;
	background-position: top right;
	.card-footer {		
		padding-right: 3rem;
		padding-bottom: 1.5rem;
		position: relative; 
	}
	.card-title {
		color: @black;
		font-weight: @font-weight-bold;
		text-decoration: none;
		i {
			font-size: 1rem;
			position: absolute;
			right: 1.25rem;
			bottom: 1.75rem; 
		}
	}
}

.card-pomoc-podpora {
	border: 1px solid @gray3;

	.card-header {
		padding-top: 2rem;
		padding-bottom: 1.1rem;
	}

	.with-image {
		height: 8.2rem;
		padding: 1rem 1.25rem;

		img {
			max-height: 6rem;
			max-width: 6rem;

			&.no-limit {
				max-height: 100%;
				max-width: 100%;
			}
		}
	}

	.card-body {
		padding-top: 0;
		padding-bottom: 0;

		.card-name {
			color: @primary-color;
			text-decoration: none;
		}

		ul {
			margin-top: 1.5rem;
			padding-left: 1em;
		}
		@media (max-width: 767px) {
			padding: 0 0.5em;
	        ul {
	        	padding-left: 1.5em;
		        li {
		            margin-bottom: 0em;
		        }
		    }
		}
	}

}

.card-image-left {
	flex-direction: row;
	/* flip card */
	.left-image {
		flex: 0 0 62.5%;
		height: 336px;
		position: relative;
		transition: transform 0.8s;
		transition-delay: 0.5s;
		transform-style: preserve-3d;

		img {
			display: block;
			height: auto;
			width: 100%;
			max-height: 400px;
		}

		&.inCenter {
			transform: rotateY(180deg);

			.IEfix({  
				transform: none;
			});
		}

		.flip-card-front, .flip-card-back {
			position: absolute;
			width: 100%;
			height: auto;
			backface-visibility: hidden;
		}

		.flip-card-back {
			transform: rotateY(180deg);
		}
	}

	.right-text {
		flex: 0 0 37.5%;
		padding: 1rem;
		background-color: @gray7;
		font-size: 0.9rem;

		display: flex;
		flex-direction: column;

		.text-content {
			overflow-y: auto;
		}
	}

	.big-letter {
		font-size: 6rem;
		line-height: 5rem;
	}
}

#ie-warning {
	display: none;

	.card-ie-warning {
		border: 1px solid @red;
		color: @red;
	
		padding: 0.5rem;
	
		display: flex;
	
		.icon {
			width: 50px;

			margin-right: 0.8rem;
	
			img {
				width: 100%;
				height: auto;
			}
		}
	
		.text {
			padding-top: 0.2rem;

			a {
				color: @red;
			}
		}
	}
}

@media (max-width: 767px) {
	.card-image-left {
		flex-direction: column;
		display: block;
	}
}

.card-top-img {
	height: 170px;
	width: 100%;
}

.card-list-img-wrap {
	.card-list-img {
		height: 170px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}