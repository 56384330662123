.claim-info-box {
    background-color: @highlight-color;
    color: @white;
    position: relative;
    padding: 1em;
    padding-left: 4.5em;
    .heading {
        font-weight: @font-weight-bold;
        font-size: @h5-font-size;
        margin-bottom: 0;
    }
    p {
        padding-bottom: 0;
    }
    i {
        color: @yellow;
        position: absolute;
        left: 0.1666em;
        top: 0.1666em;
        font-size: 3em;
    }
}