.job-offers {
	h1 {
		margin-bottom: 0.5rem;
	}

	.subtitle {
		font-size: 1.6rem;
	}

	.job-info-item {
		margin-bottom: 1rem;

		i {
			vertical-align: top;
			display: inline-block;
			width: 1.5rem;
			font-size: 1.5rem;
			text-align: center;
			color: @primary-color;
		}

		.inline-block {
			display: inline-block;
			margin-left: 0.5rem;
			max-width: calc(~"100% - 3rem");

			.item-value {
				color: @gray5;
				font-size: 1rem;
			}
		}
	}

	.list-square {
		list-style-type:square;
	}
}

main.iframe-use {
	max-width: 100%;

	.job-offers-list {
		color: #F0654D;

		& > .row > .offset-md-1 {
			margin-left: 0;
		}

		& > .row > .col-md-6 {
			flex: 0 0 100%;
			max-width: 100%;
		}

		.red {
			color: #F0654D;
		}

		h1.main-headline {
			display: none;
		}

		.accordion .card .card-header.with-icon.arrow::after {
			color: #F0654D;
		}
	}

	.job-offers {
		.video-preview {
			display: none;
		}
		@media (max-width: 1000px) {
			.back-arrow {
				display: block;
			}
		}
	}
}

.job-offers-list {
	.job-offers-list-item {
		margin-top: 1rem;
		border: 1px solid @gray1 !important;

		.company-logo {
			img {
				max-width: 100%;
				height: auto;
			}
		}

		@media (max-width: 576px) {
			.company-logo {
				margin-top: 1rem;
				margin-bottom: 1.5rem;
			}
		}

		.location {
			font-size: 1.2rem;
		}
	}
}

.job-offers-swiper {
	.swiper-slide {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.back-arrow {
	font-size: 2rem;
	text-align: right;
	height: 100%;
	color: @primary-color;

	i {
		cursor: pointer;
		display: inline-block;
		vertical-align: middle;
		color: @primary-color;
	}
}

@media (max-width: 1000px) {
	.back-arrow {
		display: none;
	}
}

.job-form {
	.errorfield {
		border: 1px solid @primary-color;
		color: @primary-color;
	}

	.required {
		color: @primary-color;
	}

	.driving-licenses {
		label {
			margin-right: 1rem;
		}
	}

	.upload-btn-wrapper {
		position: relative;
		overflow: hidden;
		display: inline-block;
	}

	.upload-btn-wrapper input[type=file] {
		font-size: 100px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
}

.jobCount-wrapper {
	display: inline-flex;

	.jobCount {
		background-color: @red;
		font-size: 14px;
		margin-left: 8px;
		border-radius: 50%;
		width: 26px;
		height: 26px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
	}
}