.jumbotron.info-box {
    position: relative;
    background-color: @gray4;
    .btn-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
    } 
    hr {
        margin-left: -1rem;
        margin-right: -1rem;
        border-color: @gray2;
    }
}