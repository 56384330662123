.accordion {

    & > a {
        text-decoration: none;
        .font;
    }

    & > a:hover {
        color: @black;
    }
    
    .card {
        border: 1px solid @gray1;
        border-bottom: 0;
        .card-header {
            padding: 0;
            position: relative;
            cursor: pointer;
            min-height: 2.8rem;

            .row {
                margin: 0.75rem;
                vertical-align: middle;
            }

            &.with-icon {
                .row {
                    padding-right: 2.2rem;
                }
            }
            
            &.with-icon:after {
                position: absolute;
                right: 0; 
                top: 0;
                bottom: 0;
                display: block;
                width: 3.2rem;
                font-size: 2.8rem;
                padding: 0 0.85rem;
                line-height: 2.8rem;
                text-align: center;
                border-left: 1px solid @gray1;
                color: @primary-color;
                
            }

            &.with-icon.plus:after {
                content: '-';
            }

            &.with-icon.plus.collapsed:after {
                content: '+';
            }

            &.with-icon.arrow:after {
                font-family: 'icomoon' !important;
                content: "\e904";
                font-size: 1rem;
                font-weight: bold;
            }

        }
        .card-body {  
            border-top: 1px solid @gray1;
            //box-shadow: inset 0 4px 12px 1px @gray3;
            box-shadow: inset 0px 10px 10px -10px @gray1, inset 0px -10px 10px -10px @gray1; 

            .container-fluid {
                padding: 0;
                margin-top: 0 !important;
            }
        }
        &:last-of-type {
            border-bottom: 1px solid @gray1;
        }
    }
}