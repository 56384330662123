.product-info {
    .product-text {
        margin-bottom: 1.5em;
    }
    .price-box {
        font-weight: @font-weight-bold;
        margin-bottom: 1em;
        .price-heading {

        }
        .price {
            font-size: @h5-font-size;
            display: inline-block;
        }
        small {
            color: @gray5;
            display: inline-block;
        }
    }
    .buttons {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}