.newsList {
    .list {
        margin-bottom: 3rem;

        .news-image {
            display: block;
            height: 220px;
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .date_time {
            font-size: 0.9rem;
            margin-bottom: 0.1rem;
        }

        .heading {

            a {
                text-decoration: none;
                color: @primary-color;
                font-size: 1.6rem;
            }
        }

        @media (max-width: 990px) {
            .news-image {
                margin-bottom: 0.6rem;
            }

            .heading {
                margin-bottom: 0.3rem;
            }
        }
    }
}

.newsDetail {
    .heading {
        font-size: @h1-font-size;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .date {
        text-align: center;
    }

    .article-image {
        display: block;
        margin-top: 2rem;

        img {
            width: 100%;
            height: auto;
        }
    }

    .article-content {
        margin-top: 2rem;
    }
}