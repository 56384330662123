/* BUTTONS */


a.arrowed-left {
	display: inline-block;
	padding-left: 1.5em;

	&:before {
		.is-icon;
        content: "\e904";
        display: block;
        position: absolute;
        margin-left: -1.5em;
        color: @primary-color;
        text-decoration: underline;
	}

	&:before {
		text-decoration: none;
	}
}

/* Override */
a.btn {
	text-decoration: underline;	

	&.no-underline {
		text-decoration: none;	
	}

	&.arrowed {
		position: relative;
		padding-right: 2rem;
		text-decoration: none;
	}

	&.arrowed:after {
		.is-icon;
		content: "\e904";
		display: block;
		position: absolute;
		right: 6px;
		top: 6px;
		color: @white;
	}

	&.arrowed.btn-secondary:after{
		color: #212529;
	}

	&.arrowed-back {
		position: relative;
		padding-left: 2rem;
		text-decoration: none;
	}

	&.arrowed-back:after {
		.is-icon;
		content: "\e902";
		display: block;
		position: absolute;
		left: 6px;
		top: 6px;
		color: @white;
	}

}

.btn {

	&.btn-outline-secondary {
		color: @black;
		background-color: transparent;
		border-color: @black;

		&:hover {
			color: @black;
			background-color: transparent;
			border-color: @black;
		} 	
	}

	&.btn-light {

	    color: @black;
	    background-color: @white;
	    border-color: @gray1;

		&:hover {
	    	color: @black;
	    	background-color: @white;
	    	border-color: @gray1;
		}
		i {
			color: @link-hover-color;
		}
	}

	&.btn-link,
	&.btn-outline-secondary {
		text-decoration: none !important;
		&.arrowed:after {
			color: @primary-color;
		}
		i {
			color: @link-hover-color;
		}
	}

	&.btn-link {
		color: @primary-color;
		&:hover{
			color: @secondary-color;
		}
	}

	&.btn-block {
		text-align: left;
		i {
			float: right;
			color: @secondary-color;
		}
	}

	&.btn-primary i {
		color: @white;
	}	

	&.btn-icon-left {
		padding-left: 1.5em;
		position: relative;
		i {
			position: absolute;
			left: @input-btn-padding-x;
		}
		&.btn-link i {
			left: 0;
		}
	}

	&.btn-icon-right {
		padding-right: 2em;
		position: relative;
		i {
			position: absolute;
			right: @input-btn-padding-x;
		}
		&.btn-link i {
			right: 0;
		}
	}

}

.claim-link {
	display: block;
	text-decoration: none;
	color: @highlight-color;
	padding-left: 0.5em;
	i {
		position: absolute;
		top: 0.1em;
		left: -0.5em;
	}
}