.oslavujeme {

    .heading-container {
        margin-top: 40px;
        padding: 0 90px;

        h1 {
            color: @primary-color;
            font-size: 95px;
            margin-bottom: 0;
        }
    
        h2 {
            margin-top: 0;
            color: @primary-color;
        }
    }

    section.intro {
        margin-top: 50px;
        padding-left: 7%;

        .container {
            max-width: unset;
            border-left: 2px solid @primary-color;
            border-bottom: 2px solid @primary-color;
            padding-top: 0;
            padding-left: 120px;
            padding-bottom: 50px;

            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 35px;

            position: relative;

            .start-icon {
                position: absolute;
                top: 15%;
                left: 0;
                width: 79px;
                transform: translate(-50%, -50%);

                img {
                    display: block;
                }
            }

            p {
                margin-top: 25px;
                padding: 0;
                font-size: 24px;
                line-height: 36px;
                max-width: 500px;
            }
        }
        
    }

    section.scene {
        width: 100%;
        padding-bottom: 150px;

        position: relative;

        display: grid;
        grid-template-areas: "content scrollbar-place";
        grid-template-columns: 1fr 2px;

        .scrollmagic-pin-spacer {
            box-sizing: border-box;
            align-content: start;
        }

        .content-container {
            min-height: 100vh;
            display: grid;
            
            .content {
                min-height: 100vh;
                width: 100%;
                display: grid;
                grid-template-columns: 70% 30%;

                align-items: start;

                padding: 100px;
                padding-top: 150px;
                padding-right: 100px;

                transition: opacity 250ms ease;

                .static-image {
                    max-width: 740px;
                    max-height: 450px;
                    aspect-ratio: 1000~"/"565;

                    perspective: 1000px;

                    img {
                        grid-area: images;
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        border-radius: 30px;

                        transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px , 0);
                        opacity: 1;
                        z-index: 1;

                        @supports (background: -webkit-named-image(i)) {
                            transform: unset;
                        }
                    }
                }

                .images {
                    height: auto;
                    width: auto;
                    max-width: 740px;
                    max-height: 450px;
                    aspect-ratio: 1000~"/"565;
                    
                    display: grid;
                    grid-template-areas: "images";
    
                    perspective: 1000px;

                    img {
                        grid-area: images;
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
    
                        border-radius: 30px;

                        &:first-child {
                            transform: rotate3d(0.1, 1, 0, 20deg);
                            opacity: 0.4;
                            z-index: 0;

                            @supports (background: -webkit-named-image(i)) {
                                transform: unset;
                            }
                        }

                        &:last-child {
                            transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px , 0);
                            opacity: 1;
                            z-index: 1;

                            @supports (background: -webkit-named-image(i)) {
                                transform: unset;
                            }
                        }
                    }
/*
                    &.change {
                        img:first-child {
                            animation: card-in 1s;
                            animation-direction: normal;
                            animation-fill-mode: forwards;
                            animation-direction: normal;
                        }

                        img:last-child {
                            animation: card-out 1s;
                            animation-direction: normal;
                            animation-fill-mode: forwards;
                            animation-direction: normal;
                        }
                    }

                    @keyframes card-in {
                        0% {
                            transform: rotate3d(0.1, 1, 0, 20deg);
                            opacity: 0.4;
                            z-index: 0;
                        }
                        100% {
                            transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px , 0);
                            opacity: 1;
                            z-index: 1;
                        }
                    }

                    @keyframes card-out {
                        0% {
                            transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px , 0);
                            opacity: 1;
                        }
                        50%  {
                            transform: rotate3d(0, 0.6, 2.6, 74deg) translate3d(50px, -50px, 0) scale(0.7);
                            opacity: 1;
                            transform-origin: bottom right;
                        }
                        100% {
                            transform: rotate3d(0.1, 1, 0, 20deg);
                            opacity: 0.4;
                            z-index: 0;
                        }
                    }
                    */
                }

                .text {
                    margin-left: 50px;

                    display: grid;

                    & > div {
                        grid-area: 1 ~"/" 2;

                        opacity: 0;
                        transition: opacity 2000ms ease;

                        &.visible {
                            opacity: 1;
                            z-index: 1;
                        }
                    }

                    p {
                        margin-top: 25px;
                        padding: 0;
                        font-size: 20px;
                        line-height: 29px;
                        max-width: 300px;
                    }
                }
            }
        }

        .scrollbar {
            height: ~"calc(100vh - 100px)";
            grid-area: scrollbar-place;
            background-color: @primary-color;
            position: sticky;
            top: 0;

            .event {
                display: grid;
                grid-template-columns: auto 40px;

                position: absolute;
                right: 0;
                transform: translateX(19px) translateY(50%);

                &.start {
                    display: none;
                    grid-template-columns: auto 76px;
                    top: 50px;
                    transform: translateX(36.5px) translateY(50%);
                    z-index: 2;
                }
                
                &.end {
                    grid-template-columns: auto 76px;
                    bottom: 0;
                    transform: translateX(36.5px) translateY(50%);
                }

                &.current {
                    top: 100px;
                }

                .text {
                    color: @primary-color;
                    font-size: 20px;
                    line-height: 27px;
                    padding-right: 20px;
                    
                    display: grid;
                    align-items: center;
                    
                    span {
                        grid-area: ~"1 / 2";
                        opacity: 0;

                        transition: opacity 250ms ease;

                        &.active {
                            opacity: 1;
                        }
                    }
                }

                .icon {
                    img {
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768) and (max-width: 1300px) {
    .oslavujeme {
        section {
            &.scene {
                .content-container {
                    .content {
                        .images {
                            padding-left: 50px;
                        }

                        .text {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .oslavujeme {
        section {
            padding-left: 40px;
            padding-right: 40px;

            .heading-container {
                padding: 0;

                h1 {
                    font-size: 50px;
                }

                h2 {
                    font-size: 20px;
                }
            }

            &.intro {
                margin-top: 50px;
                padding-left: 50px;

                .container {
                    padding-left: 15%;
                    padding-bottom: 60px;

                    grid-template-columns: auto;
                    gap: 0;

                    p {
                        font-size: 18px;
                        line-height: 29px;
                    }
                }
            }

            &.scene {
                padding-bottom: 50px;
                .content-container {
                    padding: 50px 0;
                    .content {
                        display: block;

                        padding: 0;

                        .static-image {
                            padding-right: 50px;

                            img {
                                transform: rotate3d(0.1, 1, 0, 20deg);

                                @supports (background: -webkit-named-image(i)) {
                                    transform: unset;
                                }
                            }
                        }

                        .images {
                            box-sizing: content-box;
                            margin-top: 50px;
                            margin-right: 80px;
                            max-width: 500px;
                            max-height: 283px;

                            img {
                                width: 100%;
                                height: 100%;


                                &:last-child {
                                    transform: rotate3d(0.1, 1, 0, 20deg) translate3d(5%, -15% , 0);

                                    @supports (background: -webkit-named-image(i)) {
                                        transform: unset;
                                    }
                                }
                            }
                        }

                        .text {
                            padding-top: 30px;
                            padding-right: 50px;
                            margin-left: 0;
                            justify-content: start;

                            p {
                                font-size: 18px;
                                line-height: 29px;
                                max-width: 500px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 359px) {
    .oslavujeme section.scene .content-container .content .text p {
        font-size: 13px;
        line-height: 17px;
    }
}