.btn-live-agent {
    background-color: @green;
    color: @white;
    position: fixed;
    right: 0;
    bottom: 3rem;
    padding: 0.8rem;
    cursor: pointer;
    @media (max-width: 767px) {
        display: none;
    }
    
    i {
        vertical-align: middle;
        margin-left: 0.5rem;
    }
}