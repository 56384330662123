#whistleblower {
    .small-font {
        font-size: 14px;
        line-height: 17px;
    }

    .error-message {
        margin-top: 4px;
        .small-font;
        color: @red;
    }

    input[type='text'],
    input[type='number'],
    input[type='email'], textarea {
        border-radius: 2px;
        background: #fff;
        border: 1px solid @gray3;
        width: 100%;
        height: 36px;
        padding: 10px 12px;
        font-size: 16px;
        line-height: 19px;
        font-family: inherit;
        outline: none;

        &::placeholder {
            color: @gray3;
            font-style: italic;
            font-weight: 300;
            opacity: 1;
        }
    }

    textarea {
        min-height: 150px;
    }

    .input-wrapper {
        & > label,
        & > .label {
            display: block;
            font-weight: 500;
            margin-bottom: 8px;
        }

        & + .input-wrapper {
            margin-top: 40px;
        }

        input.small {
            width: 140px;
        }

        .hint {
            margin-top: 8px;

            color: @gray3;
            .small-font;
            font-weight: 300;
            font-style: italic;

            letter-spacing: -0.42px;
        }
    }

    .radio-wrapper {
        label {
            display: flex;

            input[type="radio"] {
                width: 16px;
                height: 20px;
                accent-color: @red;
                margin-right: 10px;
            }
        }
    }

    .checkbox-wrapper {
            display: flex;

        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            accent-color: @red;
            margin-right: 10px;
        }
    }

    .global-error-message {
        color: @red;
        font-size: 18px;
        line-height: 22px;
    }

    .required-asterisk {
        &:after {
            content: '*';
            display: inline-block;
            margin-left: 3px;
            color: red;
        }
    }

    /*
    .radio-wrapper {
        display: block;
        position: relative;
        padding-left: 30px;
        user-select: none;
    
        & + .radio-wrapper {
            margin-top: 12px;
        }
    
        input {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
        }
    
        label {
            display: inline-block;
            padding-top: 3px;
            padding-left: 30px;
            margin-left: -30px;
            font-weight: normal;
            margin-bottom: 0;
            cursor: pointer;
        }
    
        .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: white;
            border-radius: 50%;
            border: 1px solid @gray3;
        }
    
        input:checked ~ .checkmark {
            background-color: @red;
            border-color: @red;
        }
    
        input:focus ~ .checkmark {
            outline: 1px solid @gray3;
            outline-offset: 2px;
        }
    
        input:checked:focus ~ .checkmark {
            outline: 1px solid @red;
            outline-offset: 2px;
        }
    
        input:disabled {
            & ~ .checkmark {
                border-color: rgba(0, 0, 0, 0.3);
            }
    
            & ~ div {
                color: @gray1;
            }
        }
    
        .input-group {
            margin-top: 10px;
        }
    }
    */
    
    /*
    .checkbox-wrapper {
        display: block;
        position: relative;
        padding-left: 40px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        user-select: none;
        cursor: pointer;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            top: 0;
            left: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid @gray3;
            border-radius: 2px;
            font-size: 15px;
            cursor: pointer;

            &:after {
                content: '\2713';
                font-family: 'icomoon' !important;
                color: @red;
                position: absolute;
                display: none;

                font-size: 16px;
                left: 3px;
                top: -3px;
            }
        }

        input:checked ~ .checkmark,
        input:indeterminate + .checkmark {
            border: 1px solid @red;
        }

        input:checked ~ .checkmark:after {
            display: block;
        }

        input:indeterminate + .checkmark:after {
            display: block;
            content: '';
            height: 4px;
            width: 4px;
            background-color: @red;
            border-radius: 50%;
            top: 7px;
            left: 7px;
        }

        a {
            color: @red;
        }
    }
    */
}
