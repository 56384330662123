.swiper-box-container {
    &.mobile {
        display: none;
    }

    img {
        display: block;
		width: 100%;
		height: auto;
	}

    .swiper-pagination-bullet-active {
        background: @red;
    }
    
    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
    }
}

@media (max-width: 767px) {
    .swiper-box-container {
        &.desktop {
            display: none;
        }

        &.mobile {
            display: block;
        }
    }
}
