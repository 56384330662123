.my-gallery {
  width: 100%;
  float: left;
  a {
    position: relative;
    padding-bottom: 75%;
    display: block;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  figure,
  .gallery-more {
    float: left;
    margin: 0 2% 2% 0;
    width: 31%;
    border: 1px solid @border-color;
    &:first-child {
      display: block;
      width: 100%;
      border: none;
      a {
        padding: 0;
      }
      img {
        position: static;
        height: auto;
      }
    }
  }
  .gallery-more {
    text-align: center;
    vertical-align: middle;
    a {
    }
    .text {
      text-decoration: underline;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      top: 50%;
      margin-top: -0.5em;
    }
  }
  figcaption {
    display: none;
  }
}

@media (max-width: 730px) {// mobile
	.my-gallery {
		figure {
			width: 31%;
			&:first-child {
				display: block;
				width: 100%;
			}
		}		
		.gallery-more {
			clear: left;
			width: auto;
		}
	}
}

/* skupinazse video link */

.video-preview {
  background-color: #f1f1f1;
  width: 300px;
  
  img {
    max-height: 225px; 
    max-width: 100%;
  }

  .video-preview-description {
    padding: 1rem 1.5rem;

    .video-preview-description-title {
      font-weight: 500;
      font-size: 1.3rem;
    }

    .video-preview-description-text {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
  }
}


.video-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2018;
  pointer-events: auto;

  .player-wrapper {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    width: 720px;
    height: 445px;

    margin: auto;
    z-index: 2019;

    .close-button {
      text-align: right;
      color: @gray3;
      margin-bottom: 1rem;
      cursor: pointer;
      float: right;
    }
  }  
}

@media (max-width: 730px) {// mobile
  .video-overlay {
    .player-wrapper {
      width: 100vw;
      height: 70vh;

      iframe {
        width: 100vw;
        height: 56.25vh;
      }
    }
  }
}

.video-iframe-div {
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 25px; 
  height: 0;
  
  iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }
}
