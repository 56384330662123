#searchResult {
    .searchResult {
        margin-bottom: 2rem;

        a {
            color: @primary-color;
            text-decoration: none;
            font-size: 1.2rem;
        }

        span {
            font-size: 0.9rem;

            .luceneHit {
                font-weight: bold;
            }
        }
    }
}
