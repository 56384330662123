/* NAVIGATION */



nav, .nav {
	a {
		text-decoration: none;
	}
	
	.nav-link.active {
		color: @link-hover-color;
	}
}

/* Breadcrumb */
.breadcrumb {
	a {
		text-decoration: underline;
		&.active {
			text-decoration: none;	
		}
	}	
}

/* Desktop Menu */
.main-menu {
	border-top: 1px solid @gray1;
	border-bottom: 1px solid @gray1;
}


/* Mobile Menu */
#mobile-menu {

	.menu-root {
		position: fixed;
		z-index: 1000;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(@gray8, @white);
		border-top: 1px solid @gray1;
		
		ul {
			list-style: none;
			display: table;
			width: 100%;
			padding: 0;
			margin: 0;
			
			li {
				display: table-cell;
				text-align: center;

				&.third {
					width: 33%;
				}
				
				a {
					display: inline-block;
					margin-bottom: 0;
					padding: 5px 11px 11px 11px;
					border: 1px solid transparent;
					border-top: 1px solid transparent;
					position: relative;
					font-size: @font-size-sm;
					
					&.active {
						background: @white;
						border: 1px solid @gray1;
						border-top: 1px solid @white;
						margin-top: -1px;
						
						&:before {
							content: "";
							display: block;
							top: -1px;
							left:0;
							right: 0;
							height: 1px;
							background-color: @white;
						}
					}

					&:hover {
						text-decoration: none;
					}

					i {
						display: block;
						font-size: 2rem;
						color: @highlight-color;
					}
				}
			}
		}
	}
	
	.menu-card {
		position: fixed;		
		z-index: 900;
		top: 0;
		bottom: 50px;
		left: 0;
		right: 0;
		.white-bg;
		overflow-x: hidden;
		overflow-y: scroll;
		display: none;
		
		&.active {
			display: block;
		}
			
		.mobile-menu-header {
			padding: 27px 20px;
			border-bottom: 1px solid @gray1;
			.font-size(25);
			letter-spacing: -0.025em;
			.white-bg;
		}
		
		.menu-card-body {
			padding: 33px 21px;
			position: relative;
			
			&:before {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				content: "";
				height: 10px;
				display:block;
				background-image: linear-gradient(@gray6, @white);
			}			
		}
		
		& > ul > li {
			padding-top: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid @gray1;
		}

		ul {
			list-style: none;
			padding: 0;
			
			li {
				margin: 0;
				.font-size(25);
				letter-spacing: -0.025em;
				padding-left: 20px;

				a {
					text-decoration: none;
				}
				
				&.has-children {
					position: relative;
					padding-top: 20px;
					padding-bottom: 20px;
					
					&.active a {
						.red;
					}
					
					&:after {
						position: absolute;
						right: 20px;
						.font-size(50);
						line-height: 2rem;
						content: "+";
						.red;
					}
					
					&.active:after {
						content: "-";
					}
				}
				
				&.mobile-menu-section {
					padding: 0;
					position: relative;
					display: none;

					a {
						padding-top: 20px;
						padding-bottom: 20px;
						display: block;
					}
					
					&:before {
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						content: "";
						height: 10px;
						display:block;
						background-image: linear-gradient(@gray6, @white);
					}
					
					&:after {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						content: "";
						height: 10px;
						display:block;
						background-image: linear-gradient(@white, @gray6);
					}
					
					&.active {
						display: block;
					}
				}
				
				&:last-child {
					border-bottom: 0 none;
				}				
			}
		}
	}
}

#mobile-menu-account {
	.btn.btn-outline-secondary { 
		min-width: 150px; margin-right: 23px;
		text-align: left;

		&:last-child { margin-right: 0; }
	}
}

body.mobile-menu-active {
	overflow: hidden;
	width: 100%;
	height: 100%;
}

@media (max-width: 576px) {
	.contacts-footer {
		margin-top: 1rem;
	}
}

.error-page-main-text {
	font-size: 9.4rem;
}