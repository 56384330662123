/* HEADER */



header {
	max-width: @max-width;
	margin-left: auto;
	margin-right: auto;
	
	.logo {
		float: left;
		clear: right;
		margin-left: @font-size;
		margin-right: @font-size;

		&.big {
			img {
				height: 70px;
			}
		}

		img {
			height: 50px;
			.IEfix({  
				width: 270px;
			});
		}
	}

	.header-icon {
		font-size: 45px;
		line-height: 45px;
		height: 50px;

		a {
			display: inline-block;
			text-decoration: none;
			&:hover {
				color: inherit;
				text-decoration: none;
			}
		}

		img {
			height: 50px;
			transform: translateY(-2px);
		}
	}
	
	.lang-menu {
		float: right;
		clear: both;
		.nav-link {
			font-size: 0.882rem;
			padding: 0.5rem;
			padding-right: 0;
			
			&.active {
				color: @black;
			}
		}
	}
	
	.segment-menu {
		float: left;
		margin-top: 0.5em;
	}
	
	.search-form,
	.user-menu { 
		margin-top: .5em;
		.btn i {
			font-size: 1.5em;	
			top: 0.1em;
			right: 0;
		}
	}

	.search-form {		
		.input-group > .search-input { 
			border-top-right-radius: @border-radius-small;
			border-bottom-right-radius: @border-radius-small;
		}

		.search-input {			
			width: 280px;
			padding-right: 40px;
		}

		.search-button {
			background-color: transparent;
			position: absolute;
			padding: 0; 				
			top: 0.1em;
			right: 0;
			color: @primary-color;
			z-index: 5;
			i {
			}
		}
		&.skupina-zse {
			float: right;
		}
		
	}

}


.header-mobile {
	padding-top: 1rem;
	padding-bottom: 1rem;
	.logo {
		margin-left: 0;
		img {
			height: 3rem;
		}
	}
	.lang-menu {
		position: absolute;
		top: 0;
		right: 12px;
		a {
			padding: 0;
		}
	}
}

