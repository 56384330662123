/* TYPOGRAPHY */
html, body {
    .font;

    p {
        padding: 0 0 1em 0;
        margin: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 0.5em 0;
        font-weight: normal;
        line-height: 1.2em;
    }

    .redText {
        color: @primary-color;
    }

    h1,.fsize-h1 {
        font-size: @h1-font-size;
        letter-spacing: -0.02em;
    }

    h2,.fsize-h2 {
        font-size: @h2-font-size;
        letter-spacing: -0.020em;
    }

    h3,.fsize-h3 {
        font-size: @h3-font-size;       
        letter-spacing: -0.02em;
    }

    h4,.fsize-h4 {
        font-size: @h4-font-size;   
        letter-spacing: -0.02em;
    }

    h5,.fsize-h5 {
        font-size: @h5-font-size;
    }

    a {
        cursor: pointer;

        &:focus, &:hover, &:active {
            outline: 0 none;
        }
    }
    
    .condensed {
        letter-spacing: -1px;
    }
    
    .underline {
        text-decoration: underline;
    }
}

@media (max-width: 767px) {
    body { 
        // offset for mobile menu
        margin-bottom: 68px;
    }

    /* Responsive typography */
    html, body {
        h1,.fsize-h1 {
            font-size: @h1-sm-font-size;
            letter-spacing: -0.02em;
        }

        h2,.fsize-h2 {
            font-size: @h2-sm-font-size;
            letter-spacing: -0.020em;
        }

        h3,.fsize-h3 {
            font-size: @h3-sm-font-size;       
            letter-spacing: -0.02em;
        }

        h4,.fsize-h4 {
            font-size: @h4-sm-font-size;   
            letter-spacing: -0.02em;
        }
    }
}  

/* LISTS */
ul,ol {
    padding-left: 1.5em;

    li {
        margin-bottom: @font-size;
    }
    
    &.tight {

        li {
            margin-bottom: @font-size / 2;
        }
    }
    &.extra-tight {
        padding-left: 1em;
        li {
            margin-bottom: 0em;
        }
    }
    
    &.arrowed {
        list-style: none;
        
        li:before {
            .is-icon;
            content: "\e904";
            display: block;
            position: absolute;
            margin-left: -1.5em;
            color: @primary-color;
        }
    }
    
    &.has-icons {
        list-style: none;
        
        li > i {
            color: @highlight-color;
            position: absolute;
            margin-left: -1.5em;
        }
        &.has-icons-big {
            padding-left: 2em;
            li > i {
                font-size: 2em;
                margin-left: -1.2em;
            }
        }
    }
}

ol {
    &.bordered-numbers {
        list-style: none;
        counter-reset: nm-counter;

        li {
            counter-increment: nm-counter;
            margin-bottom: 1rem;
        }

        li::before {
            content: counter(nm-counter)".";
            font-size: 1.3rem;
            margin-right: 1.5rem;
            padding: 0.2rem 0.5rem 0.2rem 0.7rem;
            border: 1px solid @black;
        }
    }

}

/* FILLER - EMPTY SPACE */

.filler {
    height: 3rem;
}

.filler-small {
    height: 1.5rem;
}

/* spat so sipkou */
.back-link {
    position: relative;

    i {
        position: absolute;
        top: 1px;
    }

    span {
        padding-left: 1.3rem;
        text-decoration: underline;
    }
}

@media (max-width: 767px) {
    .wrap-xs-2 {
        column-count: 2;
        column-gap: 45px;
    }
}

/* HR pod logom */

header {
    
    hr {
        margin-bottom: 0;
    }
}

.list-group {
    a {
        text-decoration: none;
    }

    .list-group-item.active:before {
        display: block;
        content: '•';
        position:absolute;
        left: 7px;
    }
}



