
/* kontakty - riadky s borderom */

.row-bordered {
	border: 1px solid @gray1;
	border-top: 0;
	padding: 1em;

	&.row-bordered:first-of-type {
		border: 1px solid @gray1;
	}

	.text-align {
		display: flex;
		align-items: center;
	}

	.heading {
		color: @highlight-color;
		display: flex;
		align-items: center;

		a {
			vertical-align: middle;
			text-decoration: none;
			color: @highlight-color;
		}
		i {
			margin-right: 1rem;
			vertical-align: middle;
			color: @highlight-color;
			font-size: @h1-font-size;
		}

		@media (max-width: 767px) {
			margin-bottom: 1rem;
		}
	}

	.info-text {
		color: @gray9;
	}
}

/* kontakty - detail */
.div-bordered {
	border: 1px solid @gray1;
}

.tabs-buttons {
	position: relative;

	.clicked {
		position: relative;
		border: 1px solid @gray1;
		border-bottom: 1px solid @white;
		z-index: 2;

		&:hover {
			border-bottom-color: transparent; 
		}

	}

	&.shown:after {
		position: absolute;
		content: "";
		width: 100%;
		bottom: 0;
		left: 0;
		border-bottom: 1px solid @gray1;
		z-index: 1;
	}
}

.pobocky-header-top {
	padding: 1em 1.25em;
	border-bottom: 1px solid @gray1;
}

.pobocky-bottom-wrap {
	display: flex;
	flex-wrap: wrap;

	.pobocky-left-nav {
		height: auto;
		max-width: 270px;
		/*display: inline-block;*/
		flex: 1 0 auto;
		border-right: 1px solid @gray1;

		.pobocky-nav-item {
			min-height: 60px;
			border-bottom: 1px solid @gray1;
			padding: 0.5em 1.25em;

			.mesto {
				color: @primary-color;
				position: relative;

				i {
					position: absolute;
					top: 1px;
					left: -3px;
				}

				span {
					padding-left: 1rem;
				}
			}

			.ulica {
				color: @gray5;
				padding-left: 1rem;
			}
		}
	}

	.pobocky-right-detail {
		/*
		display: inline-block;
		width: calc(~"100% - 270px");
		*/
		flex: 1 0 auto;
		display: flex;
		flex-wrap: wrap;

		.pobocky-detail-empty {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: @gray5;
			min-height: 200px;
		}

		.pobocky-detail-left {
			flex: 1 0 auto;
			padding: 1em 1em;

			table.otvaracie-hodiny {
				width: 100%;

				tr {
					td:nth-of-type(1) {
						min-width: 25%;
					}
					td:nth-of-type(2) {
						min-width: 40%;
					}
					td:nth-of-type(3) {
						min-width: 30%;
					}
				}
			}
		}

		.pobocky-detail-right {
			flex: 1 0 auto;
			max-width: 265px;
			background-color: @gray4;
			padding: 0.8em;

			img {
				width: 240px;
			}

			.zodpovedna-osoba {
				margin-top: 2em;

				img {
					width: 68px;
					border-radius: 50%;
					display: inline-block;
					margin-right: 7px;
				}

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}
