.q-group {
	margin-top: 2.5rem;
}

.question {
	display: block;
	font-size: @h3-font-size;
	margin-bottom: 0.5rem;
}

.answer {
	width: 100%;
	max-width: 550px;
	height: 30px;
	line-height: 30px;
	border: 0;
	border-bottom: 1px solid @gray3;
	padding-right: 0.5rem;
	font-size: 1rem;
	font-weight: normal;
}

textarea.answer {
	border: 1px solid @gray3;
	min-height: 150px;
}

.answer-short {
	max-width: 300px;
}

label.radiob-label {
	margin-left: 0.5rem;
	font-size: 1rem;
}

.radiob-div {
	margin-top: 0.5rem;
}

label.error {
	color: @primary-color;
}

input.error {
	color: @primary-color;
	border-bottom: 1px solid rgba(242, 28, 10, 0.5);
}

.required {
	color: @primary-color;
	font-family: Arial;
}

.errorTextDiv {
	color: @primary-color;
	height: 30px;
	line-height: 30px;
	font-size: 1rem;
}

.file-upload-container {
	.file-upload-info {
		line-height: 2rem;
		font-size: 1rem;
		margin-bottom: 0.5rem;

		i.icon-skupina-zse-aktuality::before {
			color: black;
		}

		.delete-file {
			display: inline-block;
			margin-left: 1rem;
		}

		.upload-progress-container {
			position: relative;

			.upload-progress {
				display: inline-block;
				height: 15px;
				border-left: 1px solid black;
				border-right: 1px solid black;
				padding-top: 5px;
				padding-bottom: 5px;
				width: 150px;
				margin-left: 20px;
				margin-right: 10px;
	
				.upload-progress-bar {
					height: 100%;
					background-color: black;
					width: 0px;
				}
			}

			.upload-percentage {
				display: inline-block;
				position: absolute;
				top: 9px;
				font-size: 0.8rem;
				line-height: 0.8rem;
			}
		}

		.file-upload-error {
			color: @primary-color;
		}
		
	}


}