/* ==========================================================================
   Carousel
   ========================================================================== */
.carousel-height {
    height: 30em; 
}

.css-table {
    display: table;
}

.css-table-cell {
    display: table-cell;
    vertical-align: middle;
}
.carousel-wrapper {
    clear: both;
    .carousel {
        list-style: none;
        padding: 0;
        margin: 0;
        .carousel-dots {
            position: absolute;
            bottom: 1em;
            left: 2em;
            ul, li {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            li {
                display: inline-block;
                border-radius: 50%;
                border: 1px solid @secondary-color;
                width: 0.8em;
                height: 0.8em;
                margin-right: 0.1em;
                background-color: @white;
                cursor: pointer;
                &.current {
                    background-color: @secondary-color;
                }
            }
        }
        .carousel-height;
        .carousel-slide {
            .carousel-height;
            list-style: none;
            padding: 0;
            margin: 0;
            position: relative;
            vertical-align: middle;
            .css-table {
                .carousel-height;
                width: 26%;
            }
            .carousel-slide-img {
                .carousel-height;
                background-size: cover;
                background-position: center center;
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
            .slide-text-wrapper {  
                position: relative;
                z-index: 2;
                display: table;
                height: 90%;
                .slide-text {
                    display: table-cell;
                    position: relative;
                    vertical-align: top;
                    text-align: left;
                    background-color: fade(@white, 90%);
                    color: @black;
                    padding: 2em;
                    h3, h4 {
                        // font-weight: bold;
                        margin-bottom: 1em;
                        font-size: @h4-font-size;
                    }
                    p {
                        font-size: 0.9em;
                    }
                }
            }
        }
        .carousel-controls {
            font-size: 1.5em;
            height: 2em;
            position: absolute;
            left: -0.2em;
            right: -0.2em;
            top: 1.5em;
            a {
                color: @secondary-color;
                text-decoration: none;
                background: none;
                &:hover,
                &:focus,
                &:active {
                    color: @secondary-color;
                    outline: 0;
                }
                &.carousel-control-prev {
                    float: left;
                }
                &.carousel-control-next {
                    float: right;
                }
            }
        }
    }
}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .carousel-height {
        height: auto;
    }
    .carousel-wrapper {
        .carousel {
            height: auto;
            .carousel-slide {
                height: auto;
                .carousel-slide-img { 
                    position: static;
                    height: 20em;
                    background-position: right center;
                }
                .container {
                    padding: 0;
                }
                .css-table,
                .css-table-cell {
                    display: block;
                    height: auto;
                    width: 100%;
                }
                .slide-text-wrapper {
                    display: block;
                    height: auto;
                    .slide-text {
                        display: block;
                        width: auto;
                        height: auto;
                    }
                }
            }
            .carousel-controls {
                top: 2.5em;
                .carousel-control-next {
                    width: auto;
                }
                .carousel-control-prev {
                    width: auto;
                }
            }
        }
    }
}