

/* DIVIDER */
.divider {
	background-color: transparent;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwMDAiPgo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSI1IiBmaWxsPSIjMDAwMDAwIiBvcGFjaXR5PSIwLjEiPjwvcmVjdD4KPHJlY3QgeD0iMSIgd2lkdGg9IjUiIGhlaWdodD0iNSIgZmlsbD0iI0Y5RjlGOSIgb3BhY2l0eT0iMC4wIj48L3JlY3Q+Cjwvc3ZnPg==");
	background-repeat: repeat-x;
	background-size: initial;
	min-height: 0.5em;
	border: 0 none;    
	position: relative;
	z-index: 1;
	+ .carousel {
		margin-top: -0.5em;
	}
}

/* SHADOWS */

.shadow-top {
	position: relative;
	
	&:before {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		content: "";
		height: 10px;
		display:block;
		background-image: linear-gradient(@gray6, @white);
	}
}

.shadow-bottom {
	position: relative;

	&:after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		height: 10px;
		display:block;
		background-image: linear-gradient(@white, @gray6);
	}
}