table {
	width: 100%;


	th {
		font-weight: normal;
		color: @gray9;
		border-bottom: 1px solid @gray3;
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	td {
		padding-top: 0.65rem;
		padding-bottom: 0.65rem;
		vertical-align: top;
	}

	tr.bottom-border {
		border-bottom: 1px solid @gray3;
	}

	&.download {
		tbody:before {
		    content: "&nbsp;";
		    display: block;
		    line-height: 1.3em;
		    color: transparent;
		}

		.name {
			width: 45%;
		}

		.date {
			
		}

		.down {
			width: 20%;
		}

		a {
			color: @primary-color;
			text-decoration: none;
		}
	}
}