@font-face {
  font-family: 'icomoon';
  src:  url('@{path}/font/icomoon.eot?uq9l2b');
  src:  url('@{path}/font/icomoon.eot?uq9l2b#iefix') format('embedded-opentype'),
    url('@{path}/font/icomoon.ttf?uq9l2b') format('truetype'),
    url('@{path}/font/icomoon.woff?uq9l2b') format('woff'),
    url('@{path}/font/icomoon.svg?uq9l2b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-instagram:before {
  content: "\e932";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-down-2:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-left-2:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-right-2:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-arrow-up-2:before {
  content: "\e907";
}
.icon-chevron-thin-down:before {
  content: "\e908";
}
.icon-chevron-thin-left:before {
  content: "\e909";
}
.icon-chevron-thin-right:before {
  content: "\e90a";
}
.icon-chevron-thin-up:before {
  content: "\e90b";
}
.icon-elektrina:before {
  content: "\e90c";
}
.icon-elektro-doplnky:before {
  content: "\e90d";
}
.icon-euro:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-fin-z-uspor:before {
  content: "\e910";
}
.icon-helios-fot:before {
  content: "\e911";
}
.icon-helios-sol:before {
  content: "\e912";
}
.icon-klima:before {
  content: "\e913";
}
.icon-linkedin:before {
  content: "\e914";
}
.icon-menu:before {
  content: "\e915";
}
.icon-phone:before {
  content: "\e916";
}
.icon-plyn:before {
  content: "\e917";
}
.icon-setrisk:before {
  content: "\e918";
}
.icon-setrisk1:before {
  content: "\e919";
}
.icon-signal3:before {
  content: "\e91a";
}
.icon-triangle-down:before {
  content: "\e91b";
}
.icon-triangle-down-2:before {
  content: "\e91c";
}
.icon-triangle-left:before {
  content: "\e91d";
}
.icon-triangle-left-2:before {
  content: "\e91e";
}
.icon-triangle-right:before {
  content: "\e91f";
}
.icon-triangle-right-2:before {
  content: "\e920";
}
.icon-triangle-up:before {
  content: "\e921";
}
.icon-triangle-up-2:before {
  content: "\e922";
}
.icon-twitter:before {
  content: "\e923";
}
.icon-user:before {
  content: "\e924";
}
.icon-user-add:before {
  content: "\e925";
}
.icon-uspora-vody:before {
  content: "\e926";
}
.icon-usporne-osvetlenie-1:before {
  content: "\e927";
}
.icon-usporne-osvetlenie-2:before {
  content: "\e928";
}
.icon-youtube:before {
  content: "\e929";
}
.icon-zmena-adresy:before {
  content: "\e92a";
}
.icon-zmena-preddavkov:before {
  content: "\e92b";
}
.icon-zmena-sadzby-ee:before {
  content: "\e92c";
}
.icon-zmena-sadzby-plyn:before {
  content: "\e92d";
}
.icon-zse-smart-domov:before {
  content: "\e92e";
}
.icon-zse-zdravie:before {
  content: "\e92f";
}
.icon-lupa:before {
  content: "\e930";
}
.icon-nakupny-kosik:before {
  content: "\e931";
}


@font-face {
  font-family: 'icomoon-old';
  src:  url('@{path}/font/icomoon2.eot?3f11rl');
  src:  url('@{path}/font/icomoon2.eot?3f11rl#iefix') format('embedded-opentype'),
    url('@{path}/font/icomoon2.ttf?3f11rl') format('truetype'),
    url('@{path}/font/icomoon2.woff?3f11rl') format('woff'),
    url('@{path}/font/icomoon2.svg?3f11rl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


.ic-old-facebook:before { content: '\e81f'; } /* '' */
.ic-old-search:before { content: '\e820'; } /* '' */
.ic-old-encert:before { content: '\e821'; } /* '' */
.ic-old-fire:before { content: '\e822'; } /* '' */
.ic-old-poistenie:before { content: '\e823'; } /* '' */
.ic-old-revizie-plyn:before { content: '\e824'; } /* '' */
.ic-old-setrisk:before { content: '\e825'; } /* '' */
.ic-old-phone:before { content: '\e827'; } /* '' */
.ic-old-zse-asistuje:before { content: '\e60a'; } /* '' */
.ic-old-youtube:before { content: '\e828'; } /* '' */
.ic-old-zse-klub:before { content: '\e829'; } /* '' */
.ic-old-zse-profiservis:before { content: '\e82a'; } /* '' */
.ic-old-zse-zdravie:before { content: '\e82b'; } /* '' */
.ic-old-flash:before { content: '\e82c'; } /* '' */

.ic-old-none:before { content: "\ea59"; }

.ic-old-alert:before { content: '\ea59'; } /* '' */
.ic-old-angle-left:before { content: '\ea59'; } /* '' */
.ic-old-angle-right:before { content: '\ea59'; } /* '' */
.ic-old-close:before { content: '\ea59'; } /* '' */
.ic-old-down-dir:before { content: '\ea59'; } /* '' */
.ic-old-down:before { content: '\ea59'; } /* '' */
.ic-old-euro:before { content: '\ea59'; } /* '' */
.ic-old-gplus:before { content: '\ea59'; } /* '' */
.ic-old-info:before { content: '\ea59'; } /* '' */
.ic-old-left-dir:before { content: '\ea59'; } /* '' */
.ic-old-left-open:before { content: '\ea59'; } /* '' */
.ic-old-location:before { content: '\ea59'; } /* '' */
.ic-old-mail:before { content: '\ea59'; } /* '' */
.ic-old-menu:before { content: '\ea59'; } /* '' */
.ic-old-money:before { content: '\ea59'; } /* '' */
.ic-old-ok:before { content: '\ea59'; } /* '' */
.ic-old-right-dir:before { content: '\ea59'; } /* '' */
.ic-old-right-open-mini:before { content: '\ea59'; } /* '' */
.ic-old-right-open:before { content: '\ea59'; } /* '' */
.ic-old-shuffle:before, .ic-old-exchange:before { content: '\ea59'; } /* '' */
.ic-old-up:before { content: '\ea59'; } /* '' */
.ic-old-user-add:before { content: '\ea59'; } /* '' */
.ic-old-user:before { content: '\ea59'; } /* '' */
.ic-old-warning:before { content: '\ea59'; } /* '' */
.ic-old-wrench-1:before { content: '\ea59'; } /* '' */

.ic-old-down:before, .ic-old-down-dir:before {
  content: "\e608";
}
.ic-old-left:before, .ic-old-angle-left:before, .ic-old-left-dir:before, .ic-old-left-open:before {
  content: "\e609";
}
.ic-old-right:before, .ic-old-angle-right:before, .ic-old-right-dir:before, .ic-old-right-open-mini:before, .ic-old-right-open:before {
  content: "\e60b";
}

.ic-old-home:before {
  content: "\e611";
}
.ic-old-office:before {
  content: "\e612";
}
.ic-old-pencil:before {
  content: "\e616";
}
.ic-old-droplet:before {
  content: "\e617";
}
.ic-old-film:before {
  content: "\e61a";
}
.ic-old-lifebuoy:before {
  content: "\e61f";
}
.ic-old-envelop:before {
  content: "\e620";
}
.ic-old-alarm:before {
  content: "\e621";
}
.ic-old-bubble2:before {
  content: "\e625";
}
.ic-old-binoculars:before {
  content: "\e627";
}
.ic-old-wrench:before {
  content: "\e629";
}
.ic-old-hammer:before {
  content: "\e62a";
}
.ic-old-gift:before {
  content: "\e62c";
}
.ic-old-leaf:before {
  content: "\e62d";
}
.ic-old-fire2:before {
  content: "\e62e";
}
.ic-old-truck:before {
  content: "\e62f";
}
.ic-old-power:before {
  content: "\e630";
}
.ic-old-power-cord:before {
  content: "\e631";
}
.ic-old-notification:before {
  content: "\e635";
}
.ic-old-plus:before {
  content: "\e636";
}
.ic-old-minus:before {
  content: "\e637";
}
.ic-old-info2:before {
  content: "\e638";
}
.ic-old-checkmark:before {
  content: "\e639";
}
.ic-old-checkmark2:before {
  content: "\e63a";
}
.ic-old-twitter:before {
  content: "\e63c";
}
.ic-old-feed2:before {
  content: "\e63d";
}
.ic-old-vimeo:before {
  content: "\e63e";
}
.ic-old-tumblr:before {
  content: "\e63f";
}
.ic-old-skype:before {
  content: "\e644";
}
.ic-old-linkedin:before {
  content: "\e645";
}
.ic-old-file-pdf:before {
  content: "\e646";
}
.ic-old-file-word:before {
  content: "\e647";
}
.ic-old-mail:before {
  content: "\e610";
}
.ic-old-zmena-adresy:before {
  content: "\e60d";
}
.ic-old-close:before {
  content: "\e800";
}
.ic-old-down:before {
  content: "\e608";
}
.ic-old-download:before {
  content: "\e600";
}
.ic-old-ecert:before {
  content: "\e821";
}
.ic-old-efaktura:before {
  content: "\e601";
}
.ic-old-euro:before {
  content: "\e602";
}
.ic-old-facebook:before {
  content: "\e81f";
}
.ic-old-fire:before {
  content: "\e822";
}
.ic-old-flash:before {
  content: "\e82c";
}
.ic-old-gplus:before {
  content: "\e603";
}
.ic-old-left:before {
  content: "\e609";
}
.ic-old-location:before {
  content: "\e606";
}
.ic-old-menu:before {
  content: "\e604";
}
.ic-old-nastavenie:before {
  content: "\e605";
}
.ic-old-none:before {
  content: "\ea59";
}
.ic-old-phone:before {
  content: "\e827";
}
.ic-old-poistenie:before {
  content: "\e823";
}
.ic-old-revizie-plyn:before {
  content: "\e824";
}
.ic-old-right:before {
  content: "\e60b";
}
.ic-old-search:before {
  content: "\e820";
}
.ic-old-setrisk:before {
  content: "\e825";
}
.ic-old-up:before {
  content: "\e60c";
}
.ic-old-user:before {
  content: "\e607";
}
.ic-old-youtube:before {
  content: "\e828";
}
.ic-old-ziadosti:before {
  content: "\e60e";
}
.ic-old-zse-asistuje:before {
  content: "\e60a";
}
.ic-old-zse-klub:before {
  content: "\e829";
}
.ic-old-zse-profiservis:before {
  content: "\e82a";
}
.ic-old-zse-zdravie:before {
  content: "\e82b";
}
.ic-old-show:before {
  content: "\e9ce";
}
.ic-old-hide:before {
  content: "\e9d1";
}
.ic-old-user-add:before {
  content: "\e60f";
}
.ic-old-zmena-odberatela:before {
  content: "\e614";
}
.ic-old-zmena-preddavkov:before {
  content: "\e615";
}
.ic-old-check:before {
  content: "\f00c";
}
.ic-old-info:before {
  content: "\f129";
}
.ic-old-alert:before {
  content: "\f12a";
}

.ic-old-asterisk:before {
  content: "\e613";
}


.ic-old-elektromob:before {
  content: "\e613";
}
.ic-old-eneraudit:before {
  content: "\e618";
}
.ic-old-eneropt:before {
  content: "\e619";
}
.ic-old-eservis:before {
  content: "\e61b";
}
.ic-old-fin-z-uspor:before {
  content: "\e61c";
}
.ic-old-mod-osvetlenia:before {
  content: "\e61d";
}

.ic-old-balik-domov:before {
  content: "\e900";
}
.ic-old-it-pomoc:before {
  content: "\e901";
}
.ic-old-helios:before {
  content: "\e902";
}


.ic-old-heart-empty:before {
    content: "\e906";
}
.ic-old-heart-full:before, .ic-old-heart:before {
    content: "\e903";
}
.ic-old-heart-minus:before {
    content: "\e904";
}
.ic-old-heart-plus:before {
    content: "\e905";
}


/* skupina-zse */
@font-face {
  font-family: 'icomoon-skupina';
  src:  url('@{path}/font/icomoon-skupina.eot?9hx7vp');
  src:  url('@{path}/font/icomoon-skupina.eot?9hx7vp#iefix') format('embedded-opentype'),
    url('@{path}/font/icomoon-skupina.woff2?9hx7vp') format('woff2'),
    url('@{path}/font/icomoon-skupina.ttf?9hx7vp') format('truetype'),
    url('@{path}/font/icomoon-skupina.woff?9hx7vp') format('woff'),
    url('@{path}/font/icomoon-skupina.svg?9hx7vp#icomoon-skupina') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-skupina-"], [class*=" icon-skupina-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-skupina' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-skupina-nadacia-zse:before {
  content: "\e921";
  color: #f21c0a;
}
.icon-skupina-vzdelavanie:before {
  content: "\e922";
  color: #f21c0a;
}
.icon-skupina-komunity:before {
  content: "\e923";
  color: #f21c0a;
}
.icon-skupina-zivotne-prostredie:before {
  content: "\e924";
  color: #f21c0a;
}
.icon-skupina-esg-ratingy:before {
  content: "\e91f";
  color: #f21c0a;
}
.icon-skupina-esg-politiky:before {
  content: "\e920";
  color: #f21c0a;
}
.icon-skupina-sto-rokov-bold:before {
  content: "\e91d";
  color: #f21c0a;
}
.icon-skupina-sto-rokov:before {
  content: "\e91e";
  color: #f21c0a;
}
.icon-skupina-ocenenia-zamestnavatela:before {
  content: "\e91a";
  color: #f21c0a;
}
.icon-skupina-reporty-udrzatelnosti:before {
  content: "\e91b";
  color: #f21c0a;
}
.icon-skupina-uhlikove-audity:before {
  content: "\e91c";
  color: #f21c0a;
}
.icon-skupina-zelena-zima:before {
  content: "\e919";
  color: #92ba52;
}
.icon-skupina-zelene-leto:before {
  content: "\e918";
  color: #b0bb5a;
}
.icon-skupina-zse-zabava-a-sport:before {
  content: "\e917";
  color: #f21c0a;
}
.icon-skupina-zse-aktuality:before {
  content: "\e913";
  color: #f21c0a;
}
.icon-skupina-zse-fotografie:before {
  content: "\e914";
  color: #f21c0a;
}
.icon-skupina-zse-kontakt-media:before {
  content: "\e915";
  color: #f21c0a;
}
.icon-skupina-zse-videa:before {
  content: "\e916";
  color: #f21c0a;
}
.icon-skupina-zse-3ec-certifikat:before {
  content: "\e911";
  color: #f21c0a;
}
.icon-skupina-zse-nehnutelnosti-na-predaj:before {
  content: "\e912";
  color: #f21c0a;
}
.icon-skupina-zse-dcerske-spolocnosti1:before {
  content: "\e90b";
  color: #f21c0a;
}
.icon-skupina-zse-etika-transparentnost1:before {
  content: "\e90c";
  color: #f21c0a;
}
.icon-skupina-zse-historia-spolocnosti1:before {
  content: "\e90d";
  color: #f21c0a;
}
.icon-skupina-zse-predstavenstvo1:before {
  content: "\e90e";
  color: #f21c0a;
}
.icon-skupina-zse-prehlad-akcionarov1:before {
  content: "\e90f";
  color: #f21c0a;
}
.icon-skupina-zse-vyrocne-spravy1:before {
  content: "\e910";
  color: #f21c0a;
}
.icon-skupina-benefity:before {
  content: "\e906";
  color: #f21c0a;
}
.icon-skupina-preco-pracovat-v-zse:before {
  content: "\e907";
  color: #f21c0a;
}
.icon-skupina-pre-studentov-absolventov:before {
  content: "\e908";
  color: #f21c0a;
}
.icon-skupina-pre-zamestnancov:before {
  content: "\e909";
  color: #f21c0a;
}
.icon-skupina-volne-pracovne-miesta:before {
  content: "\e90a";
  color: #f21c0a;
}
.icon-skupina-zse-dcerske-spolocnosti:before {
  content: "\e900";
  color: #f21c0a;
}
.icon-skupina-zse-etika-transparentnost:before {
  content: "\e901";
  color: #f21c0a;
}
.icon-skupina-zse-historia-spolocnosti:before {
  content: "\e902";
  color: #f21c0a;
}
.icon-skupina-zse-predstavenstvo:before {
  content: "\e903";
  color: #f21c0a;
}
.icon-skupina-zse-prehlad-akcionarov:before {
  content: "\e904";
  color: #f21c0a;
}
.icon-skupina-zse-vyrocne-spravy:before {
  content: "\e905";
  color: #f21c0a;
}
