/* MAIN ZONE */


main {
	max-width: @max-width;
	margin-left: auto;
	margin-right: auto;
    &.with-bg-img {
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: top right;
        @media (max-width: 767px) {
            background-size: 100%;
            background-position: 100% 0rem;
            position: relative;
            .content {
                padding-top: 8rem;
            }
        }
    }
}