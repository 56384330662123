/* FOOTER */


footer {
	max-width: @max-width;
	margin-left: auto;
	margin-right: auto;
	background-color: @gray4;
	padding-bottom: 1em;
	
	.copyright {
		font-size: 0.824rem;
	}
	.social-icons {
		text-align: center;
		@media (min-width: 768px) {
			text-align: left;
		}
		a {
			font-size: 1.47rem;
	        text-decoration: none;
	        color: @link-hover-color;
		}
	}
}