/* PATHS */
@path: "";

/* LAYOUT & GRID */
@max-width: 1300px;

/* FONT */
@font-size: 1.062rem; // 17px
@font-size-px: 17px;
@line-height:   1.18; // 20px

@font-size-base:    1.062rem; // 17px
@font-size-lg:      (@font-size-base * 1.25);
@font-size-sm:      (@font-size-base * .875);

@line-height-lg:    1.18; // 20px
@line-height-sm:    1.18;
@line-height-base: 1.18;

@font-weight-light:  300;
@font-weight-normal: 400;
@font-weight-bold:   700;

@font-weight-base:   @font-weight-normal;
@line-height-base:   1.5;

@h1-font-size:  @font-size-base * 2.47;
@h2-font-size:  @font-size-base * 1.65;
@h3-font-size:  @font-size-base * 1.27;
@h4-font-size:  @font-size-base * 1.27;
@h5-font-size:  @font-size-base * 1;
@h6-font-size:  @font-size-base * 0.82;

@h1-sm-font-size:  @font-size-base * 2;
@h2-sm-font-size:  @font-size-base * 1.65;
@h3-sm-font-size:  @font-size-base * 1.27;
@h4-sm-font-size:  @font-size-base * 1.27;
@h5-sm-font-size:  @font-size-base * 1;
@h6-sm-font-size:  @font-size-base * 0.82;

/* COLORS */
@white: #FFF;
@black: #000;
@red: #f21c0a;
@yellow: #fbfd03;
@gray1: #bfbfbf;
@gray2: #a3a3a3;
@gray3: #cbcbcb;
@gray4: #f1f1f1;
@gray5: #4d4d4d;
@gray6: #d2d2d2;
@gray7: #f1f1f1;
@gray8: #e6e7e8;
@gray9: #666;

@zse-green: #9daf37;

@zeon-green:  #72bb6f;
@zeon-blue: #20254e;

.white { color: @white }
.white-bg { background-color: @white; }
.black { color: @black }
.black-bg { background-color: @black }
.red { color: @red }
.red-bg { background-color: @red }
.gray1 { color: @gray1 }
.gray1-bg { background-color: @gray1 }
.gray2 { color: @gray2 }
.gray2-bg { background-color: @gray2 }
.gray3 { color: @gray3 }
.gray3-bg { background-color: @gray3 }
.gray4 {color: @gray4 }
.gray4-bg {background-color: @gray4 }
.gray5 {color: @gray5 }
.gray5-bg {background-color: @gray5 }
.gray6 {color: @gray6 }
.gray6-bg {background-color: @gray6 }
.gray7 {color: @gray7 }
.gray7-bg {background-color: @gray7 }
.gray8 {color: @gray8 }
.gray8-bg {background-color: @gray8 }

.green {background-color: @green }
.green-bg {background-color: @green }

.gray-font { color: @gray1 }
.gray-font-dark { color: @gray4 }
.gray-border { color: @gray2 }
.gray-bg { background-color: @gray3 }

@border-color: @gray8;
@link-hover-color:  @zeon-green;
@highlight-color: @zeon-green;

@lighten-saturate-primary: lighten(saturate(@primary-color, 5%), 22%);
@darken-desaturate-primary: darken(desaturate(@primary-color, 15%), 3%);

/* Buttons */
@input-btn-padding-y:   0.3529411764705882rem; 
@input-btn-padding-x:   0.5882352941176471rem;
@input-btn-line-height: 1;

/* MIXINS */

.font-size(@sizeValue) {
  @remValue: (@font-size / @font-size-px) * @sizeValue;
  font-size: ~"@{remValue}";
}

.transition (@transition) {
    -webkit-transition: @transition;
    -moz-transition:    @transition;
    -ms-transition:     @transition;
    -o-transition:      @transition;
}

.box-sizing (@type: border-box) {
    -webkit-box-sizing: @type;
    -moz-box-sizing:    @type;
    box-sizing:         @type;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    -webkit-border-top-right-radius:    @topright;
    -webkit-border-bottom-right-radius: @bottomright;
    -webkit-border-bottom-left-radius:  @bottomleft;
    -webkit-border-top-left-radius:     @topleft;

    -moz-border-radius-topright:        @topright;
    -moz-border-radius-bottomright:     @bottomright;
    -moz-border-radius-bottomleft:      @bottomleft;
    -moz-border-radius-topleft:         @topleft;

    border-top-right-radius:            @topright;
    border-bottom-right-radius:         @bottomright;
    border-bottom-left-radius:          @bottomleft;
    border-top-left-radius:             @topleft;

    -moz-background-clip:    padding;
    -webkit-background-clip: padding-box;
    background-clip:         padding-box;
}

.opacity (@opacity: 0.5) {
    -webkit-opacity:    @opacity;
    -moz-opacity:       @opacity;
    opacity:        @opacity;
}

.clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }   
    *zoom: 1;
} 

.IEfix(@content) {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
        @content();
    } 
}


/* ICONS */
[class^="icon-"], [class*=" icon-"], .is-icon {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


[class^="ic-old-"], [class*=" ic-old-"] {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon-old' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* FONT */
@font-family: "Polo", sans-serif;

.font {
	font-family: @font-family;
}


/* COLORS */
@green: @zse-green;
@primary-color: @red;
@secondary-color: @black;

@border-color: @gray1;
@link-hover-color:  @red;
@highlight-color: @red;

@border-radius-small: 0px; 
@border-radius-large: 0px;
