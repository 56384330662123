
.carousel {
	.divider {
		position: absolute;
		z-index: 5;
		width: 100%;
	}

	.breadcrumb-container {
		position: absolute;
		top: 1rem;
		z-index: 5;
	}

	
	.carousel-item {
		width: 100%;

		&.no-overflow {
			max-height: 460px;
			overflow: hidden;
		}
		
		img { 
			width: 100%;
			object-fit: cover;
		}

		img.second {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}      

		.mobile {
			display: none;
		}
		.desktop {
			display: block;
		}
	}	

	.carousel-caption {
		left: 0;
		right: 0; 
		padding-left: 2rem;
		bottom: 2rem; 
		.carousel-caption-box {
			background: rgba(255,255,255,0.6);
			color: @black;
			padding: 1.5em;
		}
		a.btn-primary { margin-top: 1em; }
	}
	@media (max-width: 767px) {
		border: 1px solid @border-color;
		.carousel-item {
			overflow: hidden;
			height: auto;
			max-height: 30rem;
			
			img { 
				height: 100%; 
				object-fit: cover;   
			}
			.mobile {
				display: block;
			}
			.desktop {
				display: none;
			}
		}
		.carousel-caption {
			padding: 0;
			right: 0;
			left: 0;
			bottom: 0;
			.carousel-caption-box {
				width: 100%;
				padding: 1.5em;
				h1 {
					margin-bottom: 0;
					font-size: @h1-font-size;
				}
				br {
					display: none;
				}
			}
		}		
	}
}

